// Importaciones necesarias
import React, { useState, useEffect } from 'react';
import { supabase } from './components/components/supabaseClient';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Upload,
  message,
  Modal,
  Select
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { MoonLoader } from 'react-spinners';
const { Option } = Select;


const EditProductsPage = () => {
  // Estados
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newProduct, setNewProduct] = useState({ image: null });
  const [editingProduct, setEditingProduct] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false); // Controla la visibilidad del modal
  const [filter, setFilter] = useState('alphabetical'); // Nuevo estado para el filtro seleccionado
  const [searchTerm, setSearchTerm] = useState(''); // Nuevo estado para la barra de búsqueda

  // Función para obtener los productos
  const fetchProducts = async () => {
    try {
      console.log("Iniciando la consulta de productos...");
      const { data, error } = await supabase.from('products').select('*');
      if (error) {
        setError('Error al obtener productos');
        console.error('Error al obtener productos:', error);
      } else {
        console.log("Productos obtenidos:", data);
        setProducts(data);
      }
    } catch (err) {
      console.error("Error durante la consulta:", err);
      setError('Error al obtener productos');
    } finally {
      setLoading(false);
    }
  };

  // useEffect para obtener los productos al cargar el componente
  useEffect(() => {
    fetchProducts();
  }, []);

  // Función para aplicar los filtros
  const applyFilters = (products) => {
    if (filter === 'alphabetical') {
      return [...products].sort((a, b) => a.name.localeCompare(b.name));
    } else if (filter === 'reverse-alphabetical') {
      return [...products].sort((a, b) => b.name.localeCompare(a.name));
    } else if (filter === 'price-asc') {
      return [...products].sort((a, b) => a.price - b.price);
    } else if (filter === 'price-desc') {
      return [...products].sort((a, b) => b.price - a.price);
    } else if (filter === 'availability') {
      return [...products].sort((a, b) => b.pieces - a.pieces);
    }
    return products;
  };

  // Función para manejar la selección de filtro
  const handleFilterChange = (value) => {
    setFilter(value);
  };

  // Función para sanitizar el nombre del archivo
  const sanitizeFileName = (fileName) => {
    return fileName
      .toLowerCase()
      .replace(/\s+/g, '_')         // Reemplaza espacios por guiones bajos
      .replace(/[^\w\-\.]+/g, '')   // Remueve caracteres no alfanuméricos excepto guiones, subrayados y puntos
      .replace(/\_+/g, '_')         // Reemplaza múltiples guiones bajos por uno solo
      .replace(/^\_+|\_+$/g, '');   // Remueve guiones bajos al inicio o al final
  };

  // Función para manejar la adición de un producto
  const handleAddProduct = async (values) => {
    console.log('newProduct.image en handleAddProduct:', newProduct.image);
    if (!newProduct.image) {
      console.error("No se ha seleccionado un archivo de imagen");
      message.error('Por favor, selecciona una imagen para el producto.');
      return;
    }

    try {
      const file = newProduct.image;
      let imageUrl = '';

      if (file) {
        // Sanitizar el nombre del archivo
        const sanitizedFileName = `${Date.now()}_${sanitizeFileName(file.name)}`;

        console.log("Iniciando la carga de imagen...");
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from('product-images')
          .upload(sanitizedFileName, file);

        if (uploadError) {
          console.error('Error al cargar el archivo:', uploadError);
          message.error('Error al cargar la imagen.');
          return;
        }

        if (uploadData && uploadData.path) {
          const { data: publicUrlData } = supabase
            .storage
            .from('product-images')
            .getPublicUrl(uploadData.path);

          imageUrl = publicUrlData.publicUrl;

          if (!imageUrl) {
            console.error('No se pudo obtener una URL pública válida.');
            message.error('Error al obtener la URL de la imagen.');
            return;
          }

          console.log('URL de la imagen obtenida:', imageUrl);
        } else {
          console.error('No se pudo obtener el path de la imagen subida.');
          message.error('Error al subir la imagen.');
          return;
        }
      }

      const price = Number(values.price);
      const pieces = Number(values.pieces);

      if (isNaN(price) || isNaN(pieces)) {
        message.error('El precio y la cantidad deben ser números válidos.');
        return;
      }

      const { data: insertedData, error: insertError } = await supabase
        .from('products')
        .insert([{ 
          name: values.name, 
          price: price, 
          pieces: pieces, 
          image_url: imageUrl 
        }])
        .select();

      if (insertError) throw insertError;

      if (insertedData && insertedData.length > 0) {
        setProducts((prevProducts) => [...prevProducts, insertedData[0]]);
        console.log('Producto agregado:', insertedData[0]);
        message.success('Producto agregado exitosamente');
      } else {
        console.error('No se insertaron los datos del producto.');
        message.error('Error al agregar el producto.');
      }

      // Resetear el formulario y el estado
      setNewProduct({ image: null });
      setFileList([]);
      form.resetFields();
    } catch (error) {
      console.error('Error al agregar el producto:', error);
      setError('Error al agregar el producto');
      message.error('Error al agregar el producto.');
    }
  };

  const handleFileChange = (info) => {
    console.log('handleFileChange llamado:', info);
    let newFileList = [...info.fileList];
    // Limitar a un solo archivo
    newFileList = newFileList.slice(-1);
    setFileList(newFileList);

    const file = newFileList[0]?.originFileObj || newFileList[0];
    if (file) {
      console.log('Archivo seleccionado:', file);
      setNewProduct({ image: file });
    } else {
      console.log('No se ha seleccionado un archivo de imagen');
      setNewProduct({ image: null });
    }
  };

  // Función para manejar la edición de un producto
  const handleEditProduct = (product) => {
    setEditingProduct(product);
    form.setFieldsValue({ name: product.name, price: product.price, pieces: product.pieces });
    setIsModalVisible(true); // Abre el modal al hacer clic en editar
  };

  // Función para manejar la actualización de un producto
  const handleUpdateProduct = async (values) => {
    if (editingProduct) {
      try {
        const updates = {
          name: values.name,
          price: Number(values.price),
          pieces: Number(values.pieces),
        };

        if (newProduct.image) {
          const sanitizedFileName = `${Date.now()}_${sanitizeFileName(newProduct.image.name)}`;

          const { data: uploadData, error: uploadError } = await supabase.storage
            .from('product-images')
            .upload(sanitizedFileName, newProduct.image);

          if (uploadError) {
            console.error('Error al cargar el archivo:', uploadError);
            message.error('Error al cargar la imagen.');
            return;
          }

          if (uploadData && uploadData.path) {
            const { data: publicUrlData } = supabase
              .storage
              .from('product-images')
              .getPublicUrl(uploadData.path);

            updates.image_url = publicUrlData.publicUrl;
          } else {
            console.error('No se pudo obtener el path de la imagen subida.');
            message.error('Error al subir la imagen.');
            return;
          }
        }

        const { error } = await supabase
          .from('products')
          .update(updates)
          .eq('id', editingProduct.id);

        if (error) throw error;

        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === editingProduct.id ? { ...product, ...updates } : product
          )
        );

        setEditingProduct(null);
        setNewProduct({ image: null });
        setFileList([]);
        form.resetFields();
        message.success('Producto actualizado exitosamente');
        setIsModalVisible(false); // Cierra el modal después de actualizar
      } catch (error) {
        console.error('Error al actualizar el producto:', error);
        setError('Error al actualizar el producto');
        message.error('Error al actualizar el producto');
      }
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const { error } = await supabase
        .from('products')
        .delete()
        .eq('id', id);

      if (error) throw error;

      setProducts((prevProducts) => prevProducts.filter(product => product.id !== id));
      message.success('Producto eliminado exitosamente');
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
      setError('Error al eliminar el producto');
      message.error('Error al eliminar el producto');
    }
  };

  // Función para cerrar el modal
  const handleCancelModal = () => {
    setIsModalVisible(false);
    setEditingProduct(null);
    setNewProduct({ image: null });
    form.resetFields();
  };

  if (loading) {
    return (
      <div className="loading-wrapper">
        <MoonLoader 
                className='loader-wrapper'
                color='#AA0000'
        /> 
      </div>
    );
  }
  if (error) return <p>{error}</p>;

  return (
    <section>
      <div className="container">
        <div className="edit-products-page mt-20 mb-75">
          <h2 className='txt-align-center mtb-20'>{editingProduct ? 'Actualizar Producto' : 'Agregar Nuevo Producto'}</h2>
          
          
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={(values) => {
              if (editingProduct) {
                handleUpdateProduct(values);
              } else {
                handleAddProduct(values);
              }
            }}
          >
            
            <div className='add-product gap-8 f-h'>
              <Form.Item className='label-product-name' label="Nombre del Producto" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item className='w-100' label="Precio" name="price" rules={[{ required: true }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item className='w-100'  label="Cantidad" name="pieces" rules={[{ required: true }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Imagen">
                <Upload
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                  fileList={fileList}
                  maxCount={1}
                >
                  <Button icon={<PlusOutlined />}>Subir imagen</Button>
                </Upload>
              </Form.Item>
              <Form.Item label=" ">
                <Button type="primary" htmlType="submit">
                  {editingProduct ? 'Actualizar Producto' : 'Agregar Producto'}
                </Button>
              </Form.Item>
            </div>
          </Form>

          {/* Filtro para ordenar productos */}
          {/* Barra de búsqueda */}
          <div className='filter-products txt-align-center'>
          <div className='search-bar txt-align-center' style={{ marginBottom: '16px' }}>
            <Input
              placeholder="Buscar producto por nombre..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              allowClear
              style={{ width: '250px', margin: '0 auto' }}
            />
          </div>
          <div>
            <label className='mr-16'>Ordenar por:</label>
            <Select style={{ width: 250 }} defaultValue="alphabetical" onChange={handleFilterChange}>
              <Option value="alphabetical">Orden alfabético (A-Z)</Option>
              <Option value="reverse-alphabetical">Orden alfabético (Z-A)</Option>
              <Option value="price-asc">Precio (Menor a mayor)</Option>
              <Option value="price-desc">Precio (Mayor a menor)</Option>
              <Option value="availability">Disponibilidad (Mayor a menor)</Option>
            </Select>
          </div>
          </div>

          <ul>
            {applyFilters(
              products.filter((product) =>
                product.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
            ).map((product) => (
              <div className='card-white edit-products-card' key={product.id}>
                <li>
                  <img
                    className='edit-products-image'
                    src={product.image_url}
                    alt="Imagen del Producto"
                  />
                  <div className='bold font-xl'>{product.name}</div>
                  <div className='bold font-l'>{product.pieces} unidades</div>
                  <div className='product-price'>${product.price}</div>
                  <Button onClick={() => handleEditProduct(product)}>Editar</Button>
                  <Button danger onClick={() => handleDeleteProduct(product.id)}>Eliminar</Button>
                </li>
              </div>
            ))}
          </ul>

          {/* Modal para editar el producto */}
          <Modal
            title="Editar Producto"
            visible={isModalVisible}
            onCancel={handleCancelModal}
            footer={null} // Los botones de cancelar y guardar están en el formulario
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleUpdateProduct}
            >
              <Form.Item label="Nombre del Producto" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Precio" name="price" rules={[{ required: true }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Cantidad" name="pieces" rules={[{ required: true }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Imagen">
                <Upload
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                  fileList={fileList}
                  maxCount={1}
                >
                  <Button icon={<PlusOutlined />}>Subir imagen</Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Guardar Cambios
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default EditProductsPage;
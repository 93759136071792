import ProductList from '../components/components/products/productList.jsx';
import Footer from '../components/components/Footer.jsx';
import VideoSlider from '../Slider-home.jsx';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../components/context/authContext';
import { Link } from 'react-router-dom';
import HeroSlider from '../components/components/HeroSlider.jsx' 
import AdminMaintenanceSwitch from './AdminMaintenanceSwitch.jsx';
import InventoryReport from '../components/components/InventoryReport.jsx'
import { supabase } from '../components/components/supabaseClient.js'; // Asegúrate de importar correctamente supabase
import { MoonLoader } from 'react-spinners';

const Home = () => {
  const { user } = useAuth();
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState('Estamos actualizando el sitio. Por favor, vuelva más tarde.');
  const [loading, setLoading] = useState(true);
  const [newMessage, setNewMessage] = useState(''); // Para que el admin pueda editar el mensaje
  const userName = user?.user_metadata?.full_name ?? 'Usuario';

  useEffect(() => {
    const checkMaintenanceStatus = async () => {
      try {
        const { data, error } = await supabase
          .from('site_status')
          .select('is_maintenance, maintenance_message')
          .single(); // Obtenemos el estado de mantenimiento y el mensaje

        if (error) {
          throw new Error('Error al obtener el estado del sitio.');
        }

        setIsMaintenance(data.is_maintenance); // Actualizamos el estado de mantenimiento
        setMaintenanceMessage(data.maintenance_message || maintenanceMessage); // Actualizamos el mensaje de mantenimiento
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Detenemos el estado de carga
      }
    };

    checkMaintenanceStatus();
  }, []);

  // Función para que el admin actualice el mensaje de mantenimiento
  const updateMaintenanceMessage = async () => {
    try {
      const { error } = await supabase
        .from('site_status')
        .update({ maintenance_message: newMessage })
        .eq('id', 1); // Asegúrate de que `id` sea el valor correcto

      if (error) {
        throw new Error('Error al actualizar el mensaje de mantenimiento.');
      }

      setMaintenanceMessage(newMessage); // Actualizamos el estado con el nuevo mensaje
      setNewMessage(''); // Limpiamos el input
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return (
      <div className="loading-wrapper">
        <MoonLoader 
                className='loader-wrapper'
                color='#AA0000'
        /> 
      </div>
    );
  }

  // Lógica para mostrar la pantalla de mantenimiento solo para clientes
  if (isMaintenance && user?.role !== 'admin') {
    return (
      <section>
        <div className="container f-v a-c jc-c"> 
          <div className='maintenance-wrapper'>
            <div className='loader-wrapper'>
              <MoonLoader color="#AA0000" size={100} />
            </div>
            <img src="https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/product-images/cargo-container.webp?t=2024-09-30T23%3A48%3A40.994Z" alt="" />
            <h1 className='txt-align-center'>{maintenanceMessage}</h1>
          </div>
      </div>
      </section>
    );
  }

  return (
    <>
      <section>
        <div className='container'>
          {/* Funcionalidad para clientes */}
          {user?.role === 'client' && (
            <>
              <HeroSlider className="mtb-20" />
              <ProductList />
            </>
          )}

          {/* Funcionalidad para administradores */}
          {user?.role === 'admin' && (
            <>
              <h1 className='txt-align-center mtb-20'>¡Bienvenido {userName}!</h1>

                <Link to="/punto-de-venta-busqueda" className="admin-item admin-item-4 f-v jc-c a-c mb-16">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <path d="M24 15V3H8v12H3v14h26V15h-5zM10 5h12v10H10V5zm17 22H5V17h22v10z" fill="#ffffff" class="color000000 svgShape"></path>
                    <path d="M12 9h8v1h-8zm0 2h8v1h-8zm7 11h-6v-1h-1v2h8v-2h-1z" fill="#ffffff" class="color000000 svgShape"></path>
                  </svg>
                  Punto de Venta
                </Link>
              <div className='admin-wrapper'>
                <Link to="/admin/edit-products">
                  <button className="admin-item admin-item-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="bordercolor">
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                    <path d="M17.75 7 14 3.25 4.15 13.1c-.1.1-.15.22-.15.36v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.75 7zm2.96-2.96a.996.996 0 0 0 0-1.41L18.37.29a.996.996 0 0 0-1.41 0L15 2.25 18.75 6l1.96-1.96z" fill="#ffffff" class="color000000 svgShape"></path>
                    <path fill-opacity=".36" d="M2 20h20c1.1 0 2 .9 2 2s-.9 2-2 2H2c-1.1 0-2-.9-2-2s.9-2 2-2z" fill="#ffffff" class="color000000 svgShape"></path>
                  </svg>
                    Ir al Panel de Administración
                  </button>
                </Link>
                <Link className='admin-item admin-item-2' to="/admin">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.65 47.65" id="Orders">
                    <path fill="#ffffff" d="M28.6 0v19.51a.68.68 0 0 1-1.17.55l-5-5.34a.65.65 0 0 0-1 0l-5 5.34a.68.68 0 0 1-1.17-.55V0Z" class="color231f20 svgShape"></path>
                    <path fill="#ffffff" d="M38.63 0h-7v25.57a1 1 0 0 1-1.71.73l-7.27-7a1 1 0 0 0-1.4 0l-7.27 7a1 1 0 0 1-1.71-.73V0H9.01a9 9 0 0 0-9 9v29.62a9 9 0 0 0 9 9h29.62a9 9 0 0 0 9-9V9.01a9 9 0 0 0-9-9.01Zm-6.22 42.35h-23a1.5 1.5 0 0 1 0-3h23a1.5 1.5 0 0 1 0 3Zm8.36-7H9.39a1.5 1.5 0 0 1 0-3h31.38a1.5 1.5 0 0 1 0 3Z" class="color231f20 svgShape"></path>
                  </svg>
                  Ver Historial de Pedidos
                </Link>
                <AdminMaintenanceSwitch className="admin-item switch-wrapper"/>
                <Link to="/report" className="admin-item admin-item-4 f-v jc-c a-c">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="Inventory">
                    <path d="M24 15V3H8v12H3v14h26V15h-5zM10 5h12v10H10V5zm17 22H5V17h22v10z" fill="#ffffff" class="color000000 svgShape"></path>
                    <path d="M12 9h8v1h-8zm0 2h8v1h-8zm7 11h-6v-1h-1v2h8v-2h-1z" fill="#ffffff" class="color000000 svgShape"></path>
                  </svg>
                  Reporte de Inventario
                </Link>
              </div>
              {/* Input para que el admin pueda editar el mensaje de mantenimiento */}
              <div className="maintenance-message-edit  mtb-40">
                <h2 className='txt-align-center'>Editar mensaje de mantenimiento</h2>
                <textarea
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Escribe el mensaje que verán los clientes aquí..."
                />
                <button className="btn" onClick={updateMaintenanceMessage}>
                  Actualizar Texto
                </button>
              </div>
              <ProductList />
            </>
          )}

          {/* Funcionalidad para editores (similar a clientes pero con acceso al historial de pedidos) */}
          {user?.role === 'editor' && (
            <>
              <h1 className='txt-align-center mtb-20'>¡Bienvenido {userName} (Editor)!</h1>
              <Link className='admin-item admin-item-2 mb-16' to="/admin">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.65 47.65" id="Orders">
                    <path fill="#ffffff" d="M28.6 0v19.51a.68.68 0 0 1-1.17.55l-5-5.34a.65.65 0 0 0-1 0l-5 5.34a.68.68 0 0 1-1.17-.55V0Z" class="color231f20 svgShape"></path>
                    <path fill="#ffffff" d="M38.63 0h-7v25.57a1 1 0 0 1-1.71.73l-7.27-7a1 1 0 0 0-1.4 0l-7.27 7a1 1 0 0 1-1.71-.73V0H9.01a9 9 0 0 0-9 9v29.62a9 9 0 0 0 9 9h29.62a9 9 0 0 0 9-9V9.01a9 9 0 0 0-9-9.01Zm-6.22 42.35h-23a1.5 1.5 0 0 1 0-3h23a1.5 1.5 0 0 1 0 3Zm8.36-7H9.39a1.5 1.5 0 0 1 0-3h31.38a1.5 1.5 0 0 1 0 3Z" class="color231f20 svgShape"></path>
                  </svg>
                  Ver Historial de Pedidos
                </Link>
                <Link to="/report" className="admin-item admin-item-3 f-v jc-c a-c">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="Inventory">
                    <path d="M24 15V3H8v12H3v14h26V15h-5zM10 5h12v10H10V5zm17 22H5V17h22v10z" fill="#ffffff" class="color000000 svgShape"></path>
                    <path d="M12 9h8v1h-8zm0 2h8v1h-8zm7 11h-6v-1h-1v2h8v-2h-1z" fill="#ffffff" class="color000000 svgShape"></path>
                  </svg>
                  Reporte de Inventario
                </Link>
              <HeroSlider />
              <ProductList />
            </>
          )}

          {!user?.role && (
            <>
              <HeroSlider />
              <ProductList />
              {!isMaintenance && <p>Contenido del sitio normal...</p>}
            </>
          )}
        </div>
      </section>
      <VideoSlider />
      <Footer />
    </>
  );
};


export default Home;
// import { jsPDF } from 'jspdf';
// import moment from 'moment';

// export const printTicket = (order) => {
//   // Función para devolver un número seguro en formato string con dos decimales
//   const safeNumber = (n) => (typeof n === 'number' ? n.toFixed(2) : '0.00');

//   // Crear la instancia de jsPDF en milímetros (ej. ticket pequeño: 60mm x 100mm)
//   const doc = new jsPDF({
//     orientation: 'p',
//     unit: 'mm',
//     format: [60, 100]
//   });

//   // Encabezado
//   doc.setFontSize(5);
//   doc.setFont('helvetica', 'bold');
//   doc.text("MRT Comercializadora", 2, 8);
  
//   doc.setFontSize(3);
//   doc.setFont('helvetica', 'normal');
//   doc.text("Dir. Empresa", 2, 12);
//   doc.text("Tel: 555-1234", 2, 15);
  
//   doc.setLineWidth(0.2);
//   doc.line(2, 17, 43, 17);
  
//   // Datos de la orden
//   doc.setFontSize(4);
//   doc.setFont('helvetica', 'bold');
//   doc.text(`Folio: ${order.folio}`, 2, 23);
//   const fecha = order.created_at ? moment(order.created_at).format('DD/MM/YYYY HH:mm') : '';
//   doc.text(`Fecha: ${fecha}`, 2, 27);
  
//   // Integrar los datos del cliente:
//   const cliente = order.profiles ? order.profiles.full_name : order.guest || 'Nombre no disponible';
//   doc.text(`Cliente: ${cliente}`, 2, 31);
//   doc.setFont('helvetica', 'normal');
//   doc.text(`Tel: ${order.guest_phone || 'Sin teléfono'}`, 2, 35);
  
//   // Listado de ítems
//   let y = 40;
//   order.items.forEach((item) => {
//     doc.setFontSize(3);
//     doc.text(`${item.name} x ${item.quantity}`, 2, y);
//     doc.text(`$${(item.price * item.quantity).toFixed(2)}`, 35, y, { align: 'right' });
//     y += 4;
//   });
  
//   // Totales
//   doc.line(2, y, 43, y);
//   y += 3;
//   doc.setFont('helvetica', 'bold');
//   doc.text(`Total: $${order.total.toFixed(2)}`, 2, y);
  
//   // Pie de página
//   y += 36;
//   doc.setFontSize(3);
//   doc.setFont('helvetica', 'normal');
//   doc.text("¡Gracias por su compra!", 2, y);
  
//   // Configurar autoPrint y abrir en una nueva ventana
//   doc.autoPrint();
//   doc.output('dataurlnewwindow');
// };

import { jsPDF } from 'jspdf';
import moment from 'moment';

export const printTicket = (order) => {
    console.log("Datos de la orden para imprimir:", order);

  // Función para devolver un número seguro en formato string con dos decimales
  const safeNumber = (n) => (typeof n === 'number' ? n.toFixed(2) : '0.00');

  // Crear la instancia de jsPDF en milímetros (ej. ticket pequeño: 60mm x 100mm)
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: [60, 100]
  });

  // Encabezado
  doc.setFontSize(7);
  doc.setFont('helvetica', 'bold');
  doc.text("MRT Comercializadora", 2, 8);
  
  doc.setFontSize(3);
  doc.setFont('helvetica', 'normal');
  doc.text("SIEMBRA 193, Pachuca, Mexico", 2, 12);
  doc.text("Tel: 771 129 1621", 2, 15);
  
  doc.setLineWidth(0.2);
  doc.line(2, 17, 43, 17);
  
  // Datos de la orden
  doc.setFontSize(4);
  doc.setFont('helvetica', 'bold');
  doc.text(`Folio: ${order.folio}`, 2, 23);
  const fecha = order.created_at ? moment(order.created_at).format('DD/MM/YYYY HH:mm') : '';
  doc.text(`Fecha: ${fecha}`, 2, 27);
  
  // Integrar los datos del cliente:
  const cliente =
  order.profiles && order.profiles.full_name && order.profiles.full_name.trim() !== ''
    ? order.profiles.full_name
    : (order.guest && order.guest.trim() !== '' ? order.guest : 'Nombre no disponible');
    doc.text(`Cliente: ${cliente}`, 2, 31);
    doc.setFont('helvetica', 'normal');
    doc.text(`Tel: ${order.guest_phone || 'Sin teléfono'}`, 2, 35);
  
  // Listado de ítems
  let y = 40;
  order.items.forEach((item) => {
    doc.setFontSize(3);
    doc.text(`${item.name} x ${item.quantity}`, 2, y);
    doc.text(`$${(item.price * item.quantity).toFixed(2)}`, 35, y, { align: 'right' });
    y += 4;
  });
  
  // Totales
  doc.line(2, y, 43, y);
  y += 3;
  doc.setFont('helvetica', 'bold');
  doc.text(`Total: $${order.total.toFixed(2)}`, 2, y);
  
  // Pie de página
  y += 36;
  doc.setFontSize(3);
  doc.setFont('helvetica', 'normal');
  doc.text("¡Gracias por su compra!", 2, y);
  
  // Configurar autoPrint y abrir en una nueva ventana
  doc.autoPrint();
  doc.output('dataurlnewwindow');
};
import React, { useEffect, useState } from 'react';
import { supabase } from '../components/components/supabaseClient';
import { Table, Button, Modal, Select, message } from 'antd';
import jsPDF from 'jspdf';
import moment from 'moment';

const { Option } = Select;

const POS = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchOrders();
  }, []);

  // Obtener pedidos pendientes
  const fetchOrders = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from('orders')
      .select('*')
      .eq('status', 'Pendiente')  // Obtener solo pedidos pendientes
      .order('created_at', { ascending: false });

    if (error) {
      message.error('Error al obtener pedidos');
      console.error(error);
    } else {
      setOrders(data);
    }
    setLoading(false);
  };

  // Manejar el cobro
  const handlePayment = async () => {
    if (!paymentMethod) {
      message.warning('Por favor selecciona un método de pago.');
      return;
    }

    const { error } = await supabase
      .from('orders')
      .update({
        status: 'Pagado',
        payment_method: paymentMethod,
        paid_at: new Date().toISOString(),
      })
      .eq('id', selectedOrder.id);

    if (error) {
      message.error('Error al procesar el pago');
      console.error(error);
    } else {
      message.success('Pago procesado exitosamente');
      generateReceipt(selectedOrder, paymentMethod);
      setIsModalVisible(false);
      fetchOrders(); // Actualizar la lista de pedidos
    }
  };

  // Generar ticket de compra con jsPDF
  const generateReceipt = (order, paymentMethod) => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text('MRT Comercializadora', 20, 20);
    doc.setFontSize(12);
    doc.text(`Folio: ${order.folio}`, 20, 30);
    doc.text(`Fecha: ${moment(order.created_at).format('DD/MM/YYYY')}`, 20, 40);
    doc.text(`Cliente: ${order.customer_name || 'N/A'}`, 20, 50);
    
    doc.text(`Método de pago: ${paymentMethod}`, 20, 60);
    doc.text(`Total: $${order.total}`, 20, 70);

    doc.text('Detalle de productos:', 20, 80);
    let yOffset = 90;
    const items = JSON.parse(order.items);
    items.forEach((item, index) => {
      doc.text(`${index + 1}. ${item.name} x${item.quantity} - $${item.price}`, 20, yOffset);
      yOffset += 10;
    });

    doc.text('¡Gracias por su compra!', 20, yOffset + 10);
    doc.save(`recibo_${order.folio}.pdf`);
  };
  const columns = [
    { title: 'Folio', dataIndex: 'folio', key: 'folio' },
    { 
      title: 'Cliente', 
      key: 'customer_name', 
      render: (_, order) => 
        order.guest ? order.guest : order.customer_name ? order.customer_name : 'N/A'
    },
    { title: 'Total', dataIndex: 'total', key: 'total', render: (total) => `$${total}` },
    { 
      title: 'Fecha', 
      dataIndex: 'created_at', 
      key: 'created_at', 
      render: (date) => moment(date).format('DD/MM/YYYY')
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, order) => (
        <Button 
          type="primary" 
          onClick={() => {
            setSelectedOrder(order);
            setIsModalVisible(true);
          }}
        >
          Cobrar
        </Button>
      ),
    },
  ];

  return (
    <div className="container">
      <h1 className="txt-align-center mt-20">Punto de Venta de MRT</h1>
      <Button onClick={fetchOrders} loading={loading} style={{ marginBottom: 20 }}>
        Actualizar pedidos
      </Button>
      <Table 
        dataSource={orders} 
        columns={columns} 
        rowKey="id" 
        loading={loading} 
      />

      <Modal
        title="Procesar Pago"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handlePayment}
        okText="Confirmar Pago"
        cancelText="Cancelar"
      >
        <h3>Cliente: {selectedOrder?.guest ? selectedOrder?.guest : selectedOrder?.customer_name || 'N/A'}</h3>
        <p><strong>Total:</strong> ${selectedOrder?.total}</p>
        <Select
          placeholder="Selecciona método de pago"
          style={{ width: '100%' }}
          value={paymentMethod}
          onChange={setPaymentMethod}
        >
          <Option value="efectivo">Efectivo</Option>
          <Option value="tarjeta">Tarjeta</Option>
          <Option value="transferencia">Transferencia</Option>
        </Select>
      </Modal>
    </div>
  );
};

export default POS;
import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { supabase } from '../components/supabaseClient';
import moment from 'moment';
import ProductList from './products/productList'; // Componente que muestra la lista de productos
import { useCart } from '../../components/context/cartContext';

const CounterSaleModal = ({
  visible,
  onClose,
  currentUser,
  refreshOrders,
  onSaleRegistered // Nueva prop para notificar al padre el folio del pedido registrado
}) => {
  const [form] = Form.useForm();
  // Del contexto del carrito tenemos funciones para limpiar y eliminar items
  const { cart, clearCart, removeItemFromCart } = useCart();
  const [loadingSale, setLoadingSale] = useState(false);

  // NOTA: Se eliminó cualquier autocompletado con datos del usuario, para registrar el pedido siempre como invitado.
  // Los datos se ingresan manualmente en el formulario.

  // Función para generar folio consecutivo (según tu lógica)
  const generateFolio = async () => {
    const { data: lastOrder, error: lastOrderError } = await supabase
      .from('orders')
      .select('folio')
      .order('created_at', { ascending: false })
      .limit(1);
    if (lastOrderError) {
      throw new Error('No se pudo generar el folio');
    }
    let newFolioNumber = 1;
    if (lastOrder && lastOrder.length > 0) {
      const lastFolio = lastOrder[0].folio.replace(/\D/g, '');
      const lastFolioNumber = parseInt(lastFolio, 10);
      newFolioNumber = isNaN(lastFolioNumber) ? 1 : lastFolioNumber + 1;
    }
    return String(newFolioNumber).padStart(7, '0');
  };

//   const handleCounterSaleSubmit = async (values) => {
//     if (!cart || cart.length === 0) {
//       message.error('El carrito está vacío');
//       return;
//     }
//     setLoadingSale(true);
//     try {
//       // Actualizar stock para cada producto del carrito
//       for (const item of cart) {
//         const { data: product, error: fetchError } = await supabase
//           .from('products')
//           .select('pieces')
//           .eq('id', item.id)
//           .single();
//         if (fetchError || !product) {
//           throw new Error(`Error al obtener stock para ${item.name}`);
//         }
//         if (Number(product.pieces) < item.quantity) {
//           throw new Error(`Stock insuficiente para ${item.name}`);
//         }
//         const newStock = Number(product.pieces) - item.quantity;
//         const { error: updateError } = await supabase
//           .from('products')
//           .update({ pieces: newStock })
//           .eq('id', item.id);
//         if (updateError) {
//           throw new Error(`Error al actualizar el stock de ${item.name}`);
//         }
//       }

//       // Calcular totales
//       const subtotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
//       const shippingCost = 0; // En venta de mostrador se asigna a 0
//       const total = subtotal + shippingCost;
//       const folio = await generateFolio();

//       // Construir los datos del pedido usando los valores del formulario (se registra como invitado)
//       const orderGuest = values.guestName || 'Invitado';
//       const orderGuestPhone = values.guestPhone || '';

//       // Insertar la orden en la base de datos.
//       // Se registra como invitado (user_id: null) y se guarda el empleado que realiza la venta en employee_id.
//       const { data, error } = await supabase
//         .from('orders')
//         .insert([{
//           user_id: null,
//           employee_id: currentUser?.id || null,
//           guest: orderGuest,
//           guest_phone: orderGuestPhone,
//           method: 'mostrador', // Se identifica como venta de mostrador
//           items: cart,
//           total: Number(total),
//           subtotal: Number(subtotal),
//           shipping_cost: Number(shippingCost),
//           folio,
//           print_status: false,
//           created_at: moment().format('YYYY-MM-DD HH:mm:ss')
//         }])
//         .select();
//       if (error) throw error;
//       message.success('Venta de mostrador registrada exitosamente');
//       clearCart();
//       form.resetFields();
//       // En lugar de solo cerrar el modal, llamamos a onSaleRegistered para abrir el modal de cobro
//       if (onSaleRegistered && data && data.length > 0) {
//         onSaleRegistered(data[0].folio);
//       } else {
//         onClose();
//       }
//       refreshOrders && refreshOrders();
//     } catch (err) {
//       console.error('Error en venta de mostrador:', err.message);
//       message.error(err.message);
//     } finally {
//       setLoadingSale(false);
//     }
//   };
const handleCounterSaleSubmit = async () => {
    if (!cart || cart.length === 0) {
      message.error('El carrito está vacío');
      return;
    }
    setLoadingSale(true);
    try {
      // Actualizar stock para cada producto del carrito
      for (const item of cart) {
        const { data: product, error: fetchError } = await supabase
          .from('products')
          .select('pieces')
          .eq('id', item.id)
          .single();
        if (fetchError || !product) {
          throw new Error(`Error al obtener stock para ${item.name}`);
        }
        if (Number(product.pieces) < item.quantity) {
          throw new Error(`Stock insuficiente para ${item.name}`);
        }
        const newStock = Number(product.pieces) - item.quantity;
        const { error: updateError } = await supabase
          .from('products')
          .update({ pieces: newStock })
          .eq('id', item.id);
        if (updateError) {
          throw new Error(`Error al actualizar el stock de ${item.name}`);
        }
      }
  
      // Calcular totales
      const subtotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
      const shippingCost = 0; // En venta de mostrador se asigna a 0
      const total = subtotal + shippingCost;
      const folio = await generateFolio();
  
      // Asignamos valores automáticos para ventas de mostrador
      const orderGuest = "Venta de mostrador";
      const orderGuestPhone = "7712223344";
  
      // Insertar la orden en la base de datos.
      const { data, error } = await supabase
        .from('orders')
        .insert([{
          user_id: null,
          employee_id: currentUser?.id || null,
          guest: orderGuest, // Nombre predeterminado
          guest_phone: orderGuestPhone, // Teléfono predeterminado
          method: 'mostrador',
          items: cart,
          total: Number(total),
          subtotal: Number(subtotal),
          shipping_cost: Number(shippingCost),
          folio,
          print_status: false,
          created_at: moment().format('YYYY-MM-DD HH:mm:ss')
        }])
        .select();
      if (error) throw error;
  
      message.success('Venta de mostrador registrada exitosamente');
      clearCart();
      
      // En lugar de solo cerrar el modal, llamamos a onSaleRegistered para abrir el modal de cobro
      if (onSaleRegistered && data && data.length > 0) {
        onSaleRegistered(data[0].folio);
      } else {
        onClose();
      }
      refreshOrders && refreshOrders();
    } catch (err) {
      console.error('Error en venta de mostrador:', err.message);
      message.error(err.message);
    } finally {
      setLoadingSale(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Venta de Mostrador"
      onCancel={onClose}
      width={1000}  // Ajusta el ancho para mostrar ambas columnas
      footer={null}
    >
      {/* Contenedor principal con display flex */}
      <div style={{ display: 'flex', gap: '20px' }}>
        {/* Columna 1: Listado de Productos */}
        <div style={{ flex: 2, borderRight: '1px solid #ddd', paddingRight: '20px' }}>
          <h2>Productos</h2>
          <ProductList />
        </div>
        {/* Columna 2: Detalles de la Venta */}
        <div
          style={{
            flex: 1,
            paddingLeft: '20px',
            position: 'sticky',
            top: '20px',
            backgroundColor: '#fff',
            zIndex: 1
          }}
        >
          <h2>Detalles de la Venta</h2>
          <Form form={form} layout="vertical" onFinish={handleCounterSaleSubmit}>
          {/* <Form.Item
            label="Nombre del Cliente"
            name="guestName"
            rules={[{ required: true, message: 'El nombre es requerido' }]}>
            <Input placeholder="Ingresa nombre" />
            </Form.Item>

            <Form.Item
            label="Teléfono"
            name="guestPhone"
            rules={[{ required: true, message: 'El teléfono es requerido' }]}>
            <Input placeholder="Ingresa teléfono" />
            </Form.Item> */}
            <p>
              Total a cobrar: $
              {cart.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2)}
            </p>
            <Button type="primary" htmlType="submit" loading={loadingSale}>
              Generar Ticket y Registrar Venta
            </Button>
          </Form>
          {/* Resumen de productos agregados al carrito */}
          <div style={{ marginTop: '30px' }}>
            <h3>Productos en la Venta</h3>
            {cart.length === 0 ? (
              <p>No hay productos en el carrito.</p>
            ) : (
              cart.map((item) => (
                <div
                  key={item.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    padding: '10px',
                    marginBottom: '10px'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {item.image_url && (
                      <img
                        src={item.image_url}
                        alt={item.name}
                        style={{
                          width: '60px',
                          height: '60px',
                          objectFit: 'cover',
                          marginRight: '10px'
                        }}
                      />
                    )}
                    <div>
                      <p style={{ margin: 0, fontWeight: 'bold' }}>{item.name}</p>
                      <p style={{ margin: 0 }}>Cantidad: {item.quantity}</p>
                      <p style={{ margin: 0 }}>Precio: ${item.price}</p>
                    </div>
                  </div>
                  {/* Botón para eliminar el producto del carrito */}
                  <Button
                    type="link"
                    danger
                    onClick={() => removeItemFromCart(item.id)}
                  >
                    Eliminar
                  </Button>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CounterSaleModal;